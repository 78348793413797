import { Observable } from 'rxjs';
import {
  ProductGetAllDto,
  ProductPageDto,
  ProductPageQueryParamsDto,
  ResponseProductShowDto,
} from '../dtos/product.dto';

export abstract class ProductRepository {
  abstract getAll(params?: any): Observable<ProductGetAllDto>;

  abstract getPage(
    params: ProductPageQueryParamsDto
  ): Observable<ProductPageDto>;

  abstract state(body: any): Observable<any>;

  abstract show(id: number): Observable<ResponseProductShowDto>;
}
